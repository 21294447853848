/**
 * Created by davidcaddy on 6/12/2016.
 */

import React, { Component } from 'react';
import './GraphView.css';
import {AreaChart, Area, Text, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts';

const styles = {
    visible: {
        display: 'block'
    },
    hidden: {
        display: 'none'
    }
};

class GraphView extends Component {

    constructor() {
        super();

        this.onBackgroundViewClick = this.onBackgroundViewClick.bind(this);
        this.onFrameClick = this.onFrameClick.bind(this);
        this.formatXAxisLabel = this.formatXAxisLabel.bind(this);
        this.formatYAxisLabel = this.formatYAxisLabel.bind(this);
    }

    onBackgroundViewClick(event) {
        this.props.onDone();
    }

    onFrameClick(event) {
        event.stopPropagation();
    }

    formatXAxisLabel(date) {
        let dateComponents = date.split('-');
        if (dateComponents.length >= 3) {
            return "  " + dateComponents[2] + "/" + dateComponents[1] + "/" + dateComponents[0].substring(2, 4) + "  ";
        }
        return date;
    }

    formatYAxisLabel(price) {
        if (this.props.commodityMeasuredInCents) {
            return price + "¢";
        }
        return "$" + price;
    }

    render() {
        let style = styles.hidden;
        if (this.props.show) {
            style = styles.visible;
        }

        let yAxisWidth = 50;
        if (this.props.commodityData && (this.props.commodityData.length > 0)) {
            let highestPrice = 0;
            for (let dataPoint of this.props.commodityData) {
                if (dataPoint.price > highestPrice) {
                    highestPrice = dataPoint.price;
                }
            }
            let numberOfSignificantDigits = highestPrice.toString().split(".")[0].length;
            yAxisWidth = 18 + (10 * numberOfSignificantDigits);
        }

        return(
            <div className="graphBackgroundView" onClick={this.onBackgroundViewClick} style={style}>
                <div className="graphFrame" onClick={this.onFrameClick}>
                    <div className="title"><b>{this.props.title}</b></div>
                    <div className="subtitle">{this.props.subtitle}</div>
                    <div className="graphInnerFrame">
                        <ResponsiveContainer width="100%" aspect={1.48}>
                            <AreaChart data={this.props.commodityData} margin={{top:14, right:8, left:0, bottom:0}}>
                                <defs>
                                    <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#FF5F00" stopOpacity={0.9}/>
                                        <stop offset="95%" stopColor="#FF5F00" stopOpacity={0.5}/>
                                    </linearGradient>
                                </defs>
                                <XAxis stroke="#F1F1F1" name="Price" dataKey="date" tickFormatter={this.formatXAxisLabel}/>
                                <YAxis allowDecimals={false} stroke="#F1F1F1" name="Date" width={yAxisWidth} domain={['auto', 'auto']} tickFormatter={this.formatYAxisLabel}/>
                                <CartesianGrid style={{color: "#FFFFFF"}} strokeDasharray="3 3" />
                                <Tooltip/>
                                <Text scaleToFit={true}/>
                                <Area type="monotone" name="Price" dataKey="price" stroke="#FF853D" fillOpacity={1} fill="url(#colorPrice)" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default GraphView;