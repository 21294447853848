/**
 * Created by davidcaddy on 12/12/2016.
 */

import React, { Component } from 'react';
import './LoginView.css';

import 'firebaseui/dist/firebaseui.css';

const firebase = require('firebase/app');
require('firebase/auth');

let firebaseui = require('firebaseui');
// let authUi = new firebaseui.auth.AuthUI(firebase.auth());

// auth.signInWithEmailAndPassword(email, password);
// const loginPromise = auth.createUserWithEmailAndPassword(email, password);
// loginPromise.catch(e => {cosole.log("error")});

// auth.onAuthStateChanged(firebaseUser => {
//     //Logged in
//     if (firebaseUser) {
//
//     }
// });

const styles = {
    visible: {
        display: 'block'
    },
    hidden: {
        display: 'none'
    }
};

class LoginView extends Component {

    constructor() {
        super();

        this.onBackgroundViewClick = this.onBackgroundViewClick.bind(this);
        this.onFrameClick = this.onFrameClick.bind(this);
        this.signUp = this.signUp.bind(this);
        this.logIn = this.logIn.bind(this);
        this.setUpFirebaseUI = this.setUpFirebaseUI.bind(this);
        this.tearDownFirebaseUI = this.tearDownFirebaseUI.bind(this);
    }

    componentDidMount() {
        this.auth = this.props.firebase.auth();

        this.auth.onAuthStateChanged(firebaseUser => {
            //Logged in
            if (firebaseUser) {
                // console.log(firebaseUser);
                this.props.onLogIn(firebaseUser.email);
            }
        });

        this.authUi = new firebaseui.auth.AuthUI(firebase.auth());
        this.setUpFirebaseUI();
    }

    componentWillUnmount() {
        this.tearDownFirebaseUI();
    }

    onBackgroundViewClick(event) {
        this.props.onDone();
    }

    onFrameClick(event) {
        event.stopPropagation();
    }

    signUp(event) {
        this.auth.createUserWithEmailAndPassword(this.refs.emField.value, this.refs.pwField.value);
    }

    logIn(event) {
        this.auth.signInWithEmailAndPassword(this.refs.emField.value, this.refs.pwField.value);
    }

    render() {
        let style = styles.hidden;
        if (this.props.show) {
            style = styles.visible;
        }

        return(
            <div className="loginBackgroundView" onClick={this.onBackgroundViewClick} style={style}>
                <div className="loginFrame" onClick={this.onFrameClick}>
                    <div id="firebaseui-auth"></div>
                    <input ref="emField" className="emailField" type="email" placeholder="Email"/>
                    <input ref="pwField" className="passwordField" type="password" placeholder="Password"/>
                    <button className="loginButton" type="submit" value="Submit" onClick={this.logIn}>{"Log in"}</button>
                    <button className="signUpButton" type="submit" value="Submit" onClick={this.signUp}>{"Sign up"}</button>
                </div>
            </div>
        );
    }

    setUpFirebaseUI() {
        let uiConfig = {
            'callbacks': {
                'signInSuccess': function(user) {
                    if (this.props.onSignIn) {
                        this.props.onSignIn(user);
                    }
                    return false;
                }
            },
            'signInOptions': [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.EmailAuthProvider.PROVIDER_ID
            ]
        };
        this.authUi.start('#firebaseui-auth', uiConfig);
    }

    tearDownFirebaseUI() {
        this.authUi.reset();
    }
}

export default LoginView;