/**
 * Created by davidcaddy on 12/12/2016.
 */
import React, { Component } from 'react';
import './CommodityGridCell.css';

class CommodityGridCell extends Component {

    render() {
        return (
            <div className="cell">
                <div className="cellContent" onClick={this.props.onClick}>
                    <div className="cellTitle"><b>{this.props.title}</b></div>
                    <div className="cellPrice"><b>{this.props.price}</b></div>
                    <div className="cellIcon"><img src={this.props.icon} alt={this.props.title + " icon"}/></div>
                    <div className="cellChangeBlock">
                        <div className="cellChangeIcon"><img src={this.props.priceChangeIcon} alt="Price Indicator"/></div>
                        <div className="cellChange">{this.props.priceChange}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommodityGridCell;