import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
const firebase = require('firebase/app');

let config = {
    apiKey: "AIzaSyBy476QB1hzwyvx-CrXLRmzejZNosOMfk4",
    authDomain: "quick-commodities.firebaseapp.com",
    databaseURL: "https://quick-commodities.firebaseio.com",
    storageBucket: "quick-commodities.appspot.com",
    messagingSenderId: "43806038440"
};
firebase.initializeApp(config);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

