import React, { Component } from 'react';
import './App.css';

import GraphView from './components/GraphView';
import LoginView from './components/LoginView';

import CommodityGrid from "./components/CommodityGrid"
import CommodityGridCell from "./components/CommodityGridCell"

import aluminiumIcon from "./resources/AluminiumIconWeb.svg";
import coalIcon from "./resources/CoalIconWeb.svg";
import coffeeIcon from "./resources/CoffeeIconWeb.svg";
import copperIcon from "./resources/CopperIconWeb.svg";
import cornIcon from "./resources/CornIconWeb.svg";

import crudeOilIcon from "./resources/CrudeOilIconWeb.svg";
import goldIcon from "./resources/GoldIconWeb.svg";
import ironOreIcon from "./resources/IronOreIconWeb.svg";
import leanHogsIcon from "./resources/LeanHogsIconWeb.svg";
import liveCattleIcon from "./resources/LiveCattleIconWeb.svg";

import lumberIcon from "./resources/LumberIconWeb.svg";
import naturalGasIcon from "./resources/NaturalGasIconWeb.svg";
import soybeansIcon from "./resources/SoybeansIconWeb.svg";
import sugarIcon from "./resources/SugarIconWeb.svg";
import wheatIcon from "./resources/WheatIconWeb.svg";

import brentOilIcon from "./resources/BrentOilIconWeb.svg";
import cocoaIcon from "./resources/CocoaIconWeb.svg";
import cottonIcon from "./resources/CottonIconWeb.svg";
import oatsIcon from "./resources/OatsIconWeb.svg";
import platinumIcon from "./resources/PlatinumIconWeb.svg";
import silverIcon from "./resources/SilverIconWeb.svg";

import priceUpIcon from "./resources/priceUpArrowWeb.svg";
import priceDownIcon from "./resources/priceDownArrowWeb.svg";
import priceHoldIcon from "./resources/priceHoldLineWeb.svg";

import appStoreLogo from "./resources/appStoreLogoWeb.svg";
import armchairLogo from "./resources/armchairLogoWeb.svg";
import settingsIcon from "./resources/settingsIconWeb.svg";
import accountIcon from "./resources/accountIconWeb.svg";

import betaTag from "./resources/betaTagWeb.svg";

const firebase = require('firebase/app');
require('firebase/auth');
require('firebase/database');

let moment = require("moment");

let appStoreLink = "https://itunes.apple.com/us/app/quick-commodities/id1074532887?mt=8";
let armchairLink = "http://armchairengineering.com";


const styles = {
    root: {
        position: 'absolute',
        backgroundColor: '#2D2D2D',
        height: '100% ',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        overflowY: 'auto',
        justifyContent: 'space-around',
    },
    appStoreLogo: {
        textAlign: 'center',
        position: 'relative',
        padding: 6,
        width: '100%',
        bottom: 0,
        left: 0,
        zIndex: 1
    }
};

const iconMap = {ALUMINIUM: aluminiumIcon, COAL: coalIcon, COFFEE: coffeeIcon, COPPER: copperIcon, CORN: cornIcon, 
    CRUDE_OIL: crudeOilIcon, GOLD: goldIcon, IRON_ORE: ironOreIcon, LEAN_HOGS: leanHogsIcon, LIVE_CATTLE: liveCattleIcon, 
    LUMBER: lumberIcon, NATURAL_GAS: naturalGasIcon, SOYBEANS: soybeansIcon, SUGAR: sugarIcon, WHEAT: wheatIcon,
    BRENT_OIL: brentOilIcon, COCOA: cocoaIcon, COTTON: cottonIcon, OATS: oatsIcon, PLATINUM: platinumIcon, SILVER: silverIcon};

class App extends Component {

    constructor() {
        super();
        this.state = {
            currentPrices: [],
            commodityInfo: [],
            historicalPrices: [],
            infoText: "",
            selectedCommodity: "",
            showGraphView: false,
            showLoginView: false,
            user: "Log In"
        };

        this.priceTextForCommodityWithPrice = this.priceTextForCommodityWithPrice.bind(this);
        this.changeTextForCommodityWithPrice = this.changeTextForCommodityWithPrice.bind(this);
        this.iconForPriceChange = this.iconForPriceChange.bind(this);
        this.onCommodityClick = this.onCommodityClick.bind(this);
        this.onHideGraphView = this.onHideGraphView.bind(this);

        this.onHideLoginView = this.onHideLoginView.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
        this.onLogIn = this.onLogIn.bind(this);
    }

    componentDidMount() {

        firebase.auth().signInAnonymously().catch(function(error) {
            // Handle Errors here.
            // let errorCode = error.code;
            // let errorMessage = error.message;
        });

        const rootRef = firebase.database().ref();
        const currentPricesGroup = rootRef.child("currentPrices");
        const commodityInfoGroup = rootRef.child("commodityInfo");
        const historicalPricesGroup = rootRef.child("historyMonths12");

        commodityInfoGroup.on("value", snapshot => {
            this.setState({
                commodityInfo: snapshot.val()
            });
        });

        currentPricesGroup.on("value", snapshot => {
            let priceArray = [];
            let currentData = snapshot.val();
            for (let key in currentData) {
                if (currentData.hasOwnProperty(key)) {
                    let commodity = currentData[key];
                    commodity.key = key;
                    priceArray.push({commodity});
                }
            }

            this.setState({
                currentPrices: priceArray,
                infoText: "Updated " + moment(new Date()).format("MMMM Do, h:mm a")
            });
        });

        historicalPricesGroup.on("value", snapshot => {
            let historyData = snapshot.val();
            for (let key in historyData) {
                if (historyData.hasOwnProperty(key)) {
                    historyData[key].reverse();
                }
            }

            this.setState({
                historicalPrices: historyData
            });
        });
    }

    priceTextForCommodityWithPrice(commodity, price) {
        let measuredInCents = this.state.commodityInfo[commodity].measuredInCents;

        if (measuredInCents) {
            return price.toFixed(2) + "¢";
        }

        return "$" + price.toFixed(2);
    }

    changeTextForCommodityWithPrice(commodity, change) {
        let measuredInCents = this.state.commodityInfo[commodity].measuredInCents;

        if (measuredInCents) {
            return change.toFixed(2) + "¢";
        }

        return "$" + change.toFixed(2);
    }

    iconForPriceChange(change) {
        if (change > 0.0) {
            return priceUpIcon;
        }
        else if (change < 0.0) {
            return priceDownIcon;
        }

        return priceHoldIcon;
    }

    onCommodityClick(commodity) {
        this.setState({
            selectedCommodity: commodity,
            showGraphView: true
        });
    }

    onHideGraphView(event) {
        this.setState({
            selectedCommodity: "",
            showGraphView: false
        });
    }

    onSettingsClick(event) {
        // this.setState({
        //     showLoginView: true
        // });
    }

    onHideLoginView(event) {
        this.setState({
            showLoginView: false
        });
    }

    onLogIn(user) {
        this.setState({
            user: user,
            showLoginView: false
        });
    }

    render() {
        let graphData = [];
        let graphTitle = "";
        let graphSubtitle = "";
        let measuredInCents = false;
        if (this.state.selectedCommodity && this.state.historicalPrices && this.state.commodityInfo) {
            graphData = this.state.historicalPrices[this.state.selectedCommodity];
            graphTitle = this.state.commodityInfo[this.state.selectedCommodity].displayName;
            graphSubtitle = "(" + this.state.commodityInfo[this.state.selectedCommodity].description + ")";
            measuredInCents = this.state.commodityInfo[this.state.selectedCommodity].measuredInCents;
        }
        
        return (<div id="root" className="unselectable" style={styles.root}>
            <div className="betaTag"><img src={betaTag} alt="Beta"/></div>
            {/* <div className="accountBlock" onClick={this.onSettingsClick}>{this.state.user}<img className="accountIcon" src={accountIcon} alt="Account"/></div> */}
            <CommodityGrid
                elements={this.state.currentPrices.map((cellInfo) => (
                    <CommodityGridCell
                        onClick={() => this.onCommodityClick(cellInfo.commodity.key)}
                        key={cellInfo.commodity.key}
                        title={this.state.commodityInfo[cellInfo.commodity.key].displayName}
                        icon={iconMap[cellInfo.commodity.key]}
                        price={this.priceTextForCommodityWithPrice(cellInfo.commodity.key, cellInfo.commodity.price)}
                        priceChange={this.priceTextForCommodityWithPrice(cellInfo.commodity.key, cellInfo.commodity.change)}
                        priceChangeIcon={this.iconForPriceChange(cellInfo.commodity.change)}
                    />
                ))}
            />
            <div className="infoLabel" id="info">{this.state.infoText}</div>
            <div style={styles.appStoreLogo}><a href={appStoreLink}><img src={appStoreLogo} alt="Available on the App Store"/></a></div>
            <div className="armchairLogo"><a href={armchairLink}><img src={armchairLogo} alt="Available on the App Store"/></a></div>
            {/* <div className="settingsIcon"><img src={settingsIcon} alt="Settings"/></div> */}
            <GraphView title={graphTitle} subtitle={graphSubtitle} show={this.state.showGraphView} onDone={this.onHideGraphView} commodityData={graphData} commodityMeasuredInCents={measuredInCents}/>
            <LoginView show={this.state.showLoginView} onLogIn={this.onLogIn} onDone={this.onHideLoginView} firebase={firebase}/>
        </div>);
    }
}

export default App;
