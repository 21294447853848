/**
 * Created by davidcaddy on 12/12/2016.
 */

import React, { Component } from 'react';
import './CommodityGrid.css';

class CommodityGrid extends Component {

    render() {
        return (<div className="grid">{this.props.elements}</div>);
    }
}

export default CommodityGrid;